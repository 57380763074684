<template>
  <div class="member">
    <back></back>
    <div class="up">
      <swiper ref="mySwiper" :options="swiperOption" class="first-swiper">
        <swiper-slide>
          <div class="card">
            <span class="tip f-10 white">当前等级</span>
            <div><span class="f-15">普卡</span> - <span class="grey">永久</span></div>
          </div>
          <div class="text-center f-12 m-t-50">用户免费注册成为轻松小镇居民后自动获得</div>
        </swiper-slide>
        <swiper-slide>
          <div class="card">
<!--            <span class="tip f-10 white">当前等级</span>-->
            <span class="tip f-10 white bg-gray">待解锁</span>
            <div><span class="f-15">银卡</span> - <span class="grey">1年</span></div>
          </div>
          <div class="des">
            <div class="f-13 m-b-20">解锁进度（待解锁）</div>
            <div class="f-13 m-b-20" style="display: flex;align-items: center;">
              解锁进度<span style="color: #81B3FF;">（已解锁）</span>
              <span class="arrow-down"><van-icon name="arrow-down" /></span>
            </div>
            <div class="line m-b-10">
              <van-progress :percentage="50" stroke-width="4"
                            :show-pivot="false"
                            color="#81B3FF" track-color="#C5D2E9"/>
              <div class="f-11 m-t-5">全年累计获得 250/500元石</div>
            </div>
            <div class="line m-b-10">
              <div class="f-11">绑定按摩器<span class="bold">（未完成）</span></div>
            </div>
            <div class="grey f-11 m-t-25">
              资格有效期及保级条件： <br>1年，下一年累计获得超过500元石，即可保级
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card">
            <!--            <span class="tip f-10 white">当前等级</span>-->
            <span class="tip f-10 white bg-gray">待解锁</span>
            <div><span class="f-15">金卡</span> - <span class="grey">1年</span></div>
          </div>
          <div class="des">
            <div class="f-13 m-b-20">解锁进度（待解锁）</div>
            <div class="f-13 m-b-20" style="display: flex;align-items: center;">
              解锁进度<span style="color: #81B3FF;">（已解锁）</span>
              <span class="arrow-down"><van-icon name="arrow-down" /></span>
            </div>
            <div class="line m-b-10">
              <van-progress :percentage="50" stroke-width="4"
                            :show-pivot="false"
                            color="#81B3FF" track-color="#C5D2E9"/>
              <div class="f-11 m-t-5">全年累计获得 500/3000元石</div>
            </div>
            <div class="line m-b-10">
              <van-progress :percentage="50" stroke-width="4"
                            :show-pivot="false"
                            color="#81B3FF" track-color="#C5D2E9"/>
              <div class="f-11 m-t-5">全年累计获得 500/3000元气</div>
            </div>
            <div class="line m-b-10">
              <div class="f-11">绑定按摩器<span class="bold">（已完成）</span></div>
            </div>
            <div class="grey f-11 m-t-25">
              资格有效期及保级条件： 1年，每年累计获得超过3000枚元石与3000缕元气，即可保级
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card">
            <!--            <span class="tip f-10 white">当前等级</span>-->
            <span class="tip f-10 white bg-gray">待解锁</span>
            <div><span class="f-15 white">黑卡</span> - <span class="grey">1年</span></div>
          </div>
          <div class="des">
            <div class="f-13 m-b-20">获得资格</div>
            <div class="line">
              <div class="f-11 m-b-5">
                (1)连续3年金卡保级成功，且累计获得元气超过20000缕
              </div>
              <div class="f-11">
                (2)参与轻松小镇用户共治社区，社区每年会向满足基础条件 且为社区做出突出贡献的用户发放黑卡邀请
              </div>
            </div>
            <div class="grey f-11 m-t-25">
              资格有效期及保级条件： 1年，每年累计获得超过3000枚元石与3000缕元气，即可保级
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="down">
      <div class="f-18">享受权益</div>
      <van-row :gutter="20" class="welfare">
        <van-col :span="8" class="item">
          <div class="icon">
            <img :src="require('../assets/img/m1.png')" width="100%" height="100%">
          </div>
          <div class="f-11 m-b-5">高铁/机场服务</div>
          <div class="f-11 gray">VIP不限次卡</div>
        </van-col>
        <van-col :span="8" class="item">
          <div class="icon">
            <img :src="require('../assets/img/m2.png')" width="100%" height="100%">
            <div class="loack">
              <img :src="require('../assets/img/lock.png')" width="100%" height="100%">
            </div>
          </div>
          <div class="f-11 m-b-5">倍轻松专属定制</div>
          <div class="f-11 gray">3个</div>
        </van-col>
        <van-col :span="8" class="item">
          <div class="icon">
            <img :src="require('../assets/img/m3.png')" width="100%" height="100%">
            <div class="loack">
              <img :src="require('../assets/img/lock.png')" width="100%" height="100%">
            </div>
          </div>
          <div class="f-11 m-b-5">倍轻松新品发布会</div>
          <div class="f-11 gray">VIP双人资格</div>
        </van-col>
      </van-row>
      <van-row :gutter="20" class="welfare">
        <van-col :span="8" class="item">
          <div class="icon">
            <div class="loack">
              <img :src="require('../assets/img/lock.png')" width="100%" height="100%">
            </div>
            <img :src="require('../assets/img/m4.png')" width="100%" height="100%">
          </div>
          <div class="f-11 m-b-5">双人轻松之旅</div>
          <div class="f-11 gray">全年2次</div>
        </van-col>
        <van-col :span="8" class="item">
          <div class="icon">
            <img :src="require('../assets/img/m5.png')" width="100%" height="100%">
            <div class="loack">
              <img :src="require('../assets/img/lock.png')" width="100%" height="100%">
            </div>
          </div>
          <div class="f-11 m-b-5">倍轻松新产品</div>
          <div class="f-11 gray">倍轻松新产品</div>
        </van-col>
        <van-col :span="8" class="item">
          <div class="icon">
            <img :src="require('../assets/img/m6.png')" width="100%" height="100%">
            <div class="loack">
              <img :src="require('../assets/img/lock.png')" width="100%" height="100%">
            </div>
          </div>
          <div class="f-11 m-b-5">直营门店折扣</div>
          <div class="f-11 gray">后续开放</div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import Back from '../components/Back'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Member',
  components: { Back, Swiper, SwiperSlide },
  data () {
    return {
      swiperOption: {
        slidesPerView: 1.1,
        spaceBetween: 17,
        centeredSlides: true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.member{
  position: relative;
  .up{
    background: url("../assets/img/Member-bg.png") no-repeat;
    background-size: cover;
    padding: 76px 0;
    min-height: 395px;
    .first-swiper{
      .swiper-slide{
        .card{
          height: 174px;
          background: rgba(233, 246, 255, 0.3);
          margin-bottom: 30px;
          border: 1px solid #FFFFFF;
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.4);
          border-radius: 13px;
          padding: 0 17px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          .tip{
            position: absolute;
            background-color: black;
            border-radius: 0 0 3px 3px;
            padding: 5px 10px;
            top: 0;
            left: 17px;
          }
          .bg-gray{
            background: #A9A9A9;
          }
        }
        .arrow-down{
          width: 17px;
          height: 17px;
          background: rgba(255, 255, 255, 0.5);
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.4);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .des{
          padding: 0 17px;
          .line{
            background: rgba(233, 246, 255, 0.3);
            border: 1px solid #FFFFFF;
            box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.4);
            padding: 13px 15px 8px;
            border-radius: 25px;
            &:nth-of-type(2) {
              padding: 5px 15px;
            }
          }
        }
        &:first-child{
          .card{
            background: url("../assets/img/card1.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        &:nth-child(2){
          .card{
            background: url("../assets/img/yinka.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        &:nth-child(3){
          .card{
            background: url("../assets/img/jinka.png") no-repeat;
            background-size: 100% 100%;
          }
          .line{
            background: rgba(233, 246, 255, 0.3);
            border: 1px solid #FFFFFF;
            box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.4);
            padding: 13px 15px 8px;
            border-radius: 25px;
            &:nth-of-type(2) {
              padding: 15px;
            }
          }
        }
        &:nth-child(4){
          .card{
            background: url("../assets/img/heika.png") no-repeat;
            background-size: 100% 100%;
            background-color: black;
          }
          .line{
            background: rgba(233, 246, 255, 0.3);
            border: 1px solid #FFFFFF;
            box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.4);
            padding: 13px 15px 8px;
            border-radius: 25px;
            &:nth-of-type(2) {
              padding: 15px;
            }
          }
        }
      }
    }
  }
  .down{
    padding: 17px 20px;
    .gray{
      color: #D1D1D1;
    }
    .welfare{
      margin-top: 25px;
      >.item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon{
          width: 38px;
          height: 38px;
          margin-bottom: 8px;
          position: relative;
          .loack{
            position: absolute;
            width: 10px;
            height: 11px;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}
</style>
